import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../panel-shared/shared/general.service';
import { User } from '../panel-shared/user';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

	constructor(
		private _http: HttpClient,
		public generalService: GeneralService
	) { }

	ngOnInit() {
		this._http.post(User.API_ENDPOINT + 'logout', '')
			.subscribe((response: Response) => {
				//console.log(response);
				let res = { 'results': JSON.stringify(response) };
				let myjson = JSON.parse(res.results);
				const status = myjson.status;
				if (status === 200) {
					this.generalService.logout();
				} else {
					this.generalService.logout();
				}
			});
	}

}
