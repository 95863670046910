import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../panel-shared/shared/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
    ) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler):
        Observable<HttpEvent<any>> {

        const token = this.authService.token;
        if (token) {
            req = req.clone({
                headers: req.headers.set("Token", token),
            });
            return next.handle(req);

        } else {
            return next.handle(req);
        }

    }
    //Access-Control-Expose-Headers

}