import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/panel-shared/user';

@Component({
	selector: 'app-publisher',
	templateUrl: './publisher.component.html',
	styleUrls: ['./publisher.component.css', '../../../assets/website/assets/css/bootstrap.min.css']
})
export class PublisherComponent implements OnInit {
	registerationForm: FormGroup;
	btnText = "Submit";
	loading: boolean = false;

	constructor(
		private _http: HttpClient,
		private toastr: ToastrService,
	) { }

	ngOnInit() {
		this.registerationForm = new FormGroup({
			'fname': new FormControl(null, Validators.required),
			'lname': new FormControl(null, Validators.required),
			'email': new FormControl(null, Validators.required),
			'company': new FormControl(null, Validators.required),
			'contact': new FormControl(null, Validators.required),
			'webtype': new FormControl('http://', Validators.required),
			'website': new FormControl(null, Validators.required),
			'busiaddr': new FormControl('')
		});
	}

	markFormTouched(forms) {
		for (let i in forms.controls) {
			forms.controls[i].markAsTouched();
		}
	}

	saveAdvertiser() {
		this.markFormTouched(this.registerationForm);
		if (this.registerationForm.valid) {
			this.btnText = 'Please wait...';
			this.loading = true;
			this._http
				.post(User.API_ENDPOINT + 'saveNewAdvertiser', JSON.stringify(this.registerationForm.value))
				.subscribe((response: Response) => {
					let res = { 'results': JSON.stringify(response) };
					let myjson = JSON.parse(res.results);
					const status = myjson.status;
					this.btnText = 'Submit';
					this.loading = false;
					if (status === 200) {
						this.toastr.success('Advertiser created successfully and mail sent to user', 'Advertiser Created');
						this.registerationForm.reset();
						this.registerationForm.controls['webtype'].setValue('http://');
					} else if (status == 201) {
						this.toastr.warning('Advertiser created successfully but failed to send user mail', 'Advertiser Created');
					} else if (status == 401) {
						this.toastr.warning('This email id is already register to in this platform.', 'Email Already Exists');
					} else {
						this.toastr.warning('Failed to create new Advertiser. Please contact us.', 'Failed to Create');
					}
				});
		} else {
			this.toastr.warning('Please fill all the mandatory fields.', 'Incomplete Form');
		}
	}
}
