import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { observable } from 'rxjs';
import { User } from '../panel-shared/user';
@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
	submitted = false;
	error = '';
	loading = false;
	btnText = 'UPDATE';
	model: any = {};
	errorconfpass = false;
	errornewpass = false;
	mismatcherr = false;
	load = false;
	resetForm: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private _http: HttpClient,
	) { }

	ngOnInit() {
		this.resetForm = new FormGroup({
			'newpass': new FormControl(null),
			'confpass': new FormControl(null)
		})

	}

	updatepsd() {
		let token = window.location.search.substring(7);
		let password = this.resetForm.controls['newpass'].value;
		let cpassword = this.resetForm.controls['confpass'].value;
		if (password == null) {
			this.errornewpass = true;
		} else if (cpassword == null) {
			this.errornewpass = false;
			this.errorconfpass = true;
		} else if (password != cpassword) {
			this.errorconfpass = false;
			this.mismatcherr = true;
		} else {
			this.mismatcherr = false;
			this.loading = true;
			this._http.post(User.API_ENDPOINT + 'Admin/modifyPassword', JSON.stringify({ newpassword: password, token: token }))
				.subscribe((response: Response) => {
					this.loading = false;
					let res = { 'results': JSON.stringify(response) };
					let myjson = JSON.parse(res.results);
					let status = myjson.status;
					if (status == 200) {
						this.error = 'Password Updated Successfully. Please login to access your account';
						this.router.navigate(['']);
					} else if (status == 400) {
						this.error = '* Error in updating Password';
					}
				});
		}
	}
}
