import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from './panel-shared/shared/general.service';

@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {
	constructor(
		private router: Router,
		public generalService: GeneralService
	) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (localStorage.getItem('currentUser')) {
			const r1 = next.data['role'];
			var r2 = localStorage.getItem('role');
			if (r1 > 2) {
				if (r2 === '0') {
					this.router.navigate(['admin']);
					return true;
				} else if (r2 === '2') {
					this.router.navigate(['advertiser-dashboard']);
					return true;
				} else {
					return true;
				}
			} else {
				if (r1 == r2) {
					return true;
				} else {
					this.generalService.logout();
					return false;
				}
			}
		} else {
			this.generalService.logout();
			return false;
		}
	}

}
