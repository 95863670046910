import { Injectable } from '@angular/core';
import { User } from '../user';
import { map } from 'rxjs/operators';
import { Http, Headers, Response } from '@angular/http';
import { Router } from '@angular/router';
import { AuthService } from '../shared/auth.service';
//import * as jwt_decode from "jwt-decode";

@Injectable({
	providedIn: 'root'
})
export class GeneralService {
	token: any;
	r = [];
	constructor(
		private http: Http,
		private router: Router,
		private authService: AuthService
	) { }

	login(d) {
		return this.http.post(User.API_ENDPOINT + 'loginApp', d, { headers: new Headers({ 'Content-Type': 'application/json' }) })
			.pipe(map((response: Response) => {
				//console.log(response);
				localStorage.clear();
				const token = response.json() && response.json().token;
				this.r[0] = false;
				if (token) {
					this.token = token;
					localStorage.setItem('currentUser', JSON.stringify({ token: token }));
					localStorage.setItem('role', response.json() && response.json().r);
					localStorage.setItem('fname', response.json() && response.json().name);
					this.r[0] = true;
					this.r[1] = response.json() && response.json().r;
					var currentUser = JSON.parse(localStorage.getItem('currentUser'));
					this.authService.token = currentUser && currentUser.token;
				} else {
					this.r[1] = response.json() && response.json().status;
				}
				return this.r;
			}));
	}

	logout(): void {
		// clear token remove user from local storage to log user out
		this.token = null;
		//this.r[0] = false;
		localStorage.removeItem('currentUser');
		localStorage.removeItem('role');
		//console.log(localStorage.getItem('currentUser'));
		this.router.navigate(['/']);
	}


}
