import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/panel-shared/shared/general.service';
import { User } from 'src/app/panel-shared/user';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css', '../../../assets/website/assets/css/bootstrap.min.css', '../../../assets/website/navbar.css']
})

export class HomeComponent implements OnInit {
	categories = [];
	campaignbnr = [];
	loadAPI: Promise<any>;
	apa: boolean = true;
	campend: boolean = false;
	selcat = [];
	tags = '';
	zerocatchecked: boolean = false;

	finperm: number = 131071;
	permission: number = 131071;

	city = 'Loading...';
	state = 'Loading...';
	country = 'Loading...';
	loading: boolean = false;
	currrentIndex = 0;
	locationflag = 0;
	preloadflag = 0;
	viewedcamps: any;

	ageModalCenter = "none";

	constructor(
		private _http: HttpClient,
		public generalService: GeneralService,
	) { }

	ngOnInit() {
		if (this.getCookie('AAll_Cookie') == null) {
			this.viewedcamps = ['0'];
			this.setCookie('AAll_Cookie', JSON.stringify(this.viewedcamps), 365);
		}
		this.viewedcamps = JSON.parse(this.getCookie('AAll_Cookie'));
		this.getHomeDetails();
		this.getCoordintes();
	}

	getHomeDetails() {
		this._http.post(User.API_ENDPOINT + 'getHomePreLoad', '')
			.subscribe((response: Response) => {
				let res = { 'results': JSON.stringify(response) };
				let myjson = JSON.parse(res.results);
				const status = myjson.status;
				//console.log(response)
				if (status === 200) {
					for (var i = 0; i < myjson.data['category'].length; i++) {
						this.categories.push({ cat_id: myjson.data['category'][i].cat_id, name: myjson.data['category'][i].name, selectd: false })
					}
					for (var j = 0; j < this.categories.length; j++) {
						this.categories[j].selectd = true;
						this.selcat.push(this.categories[j].cat_id);
					}
					this.zerocatchecked = true;
					if (this.locationflag == 1 && this.preloadflag == 0) {
						this.applyFilter();
					} else {
						this.preloadflag = 1;
					}
				} else {
					this.generalService.logout();
				}
			});
	}

	applyFilter() {
		this.loading = false;
		this._http.post(User.API_ENDPOINT + 'getLoadCamps',
			JSON.stringify({ selcat: this.selcat, tags: this.tags, ages: this.permission, city: this.city, state: this.state, country: this.country }))
			.subscribe((response: Response) => {
				let res = { 'results': JSON.stringify(response) };
				let myjson = JSON.parse(res.results);
				const status = myjson.status;
				console.log(response)
				document.querySelector('#material').remove();
				if (status === 200) {
					this.loading = true;
					this.viewedcamps = JSON.parse(this.getCookie('AAll_Cookie'));

					for (var m = 0; m < myjson.data['campaigns'].length; m++) {
						var type = 0;
						if (this.viewedcamps != null) {
							for (var n = 0; n < this.viewedcamps.length; n++) {
								if (myjson.data['campaigns'][m].camp_id == this.viewedcamps[n]) {
									type = 1;
									break;
								}
							}
						}
						var code = myjson.data['campaigns'][m].apacode.substring(8, myjson.data['campaigns'][m].apacode.length);
						let typesrc = 'https://adspayall.com/assets/images/image.png';
						if (code.substring(0, 1) == 'P') {
							typesrc = 'https://adspayall.com/assets/images/image.png';
						} else if (code.substring(0, 1) == 'V') {
							typesrc = 'https://adspayall.com/assets/images/video.png';
						} else if (code.substring(0, 1) == 'M') {
							typesrc = 'https://adspayall.com/assets/images/article.png';
						} else if (code.substring(0, 1) == 'A') {
							typesrc = 'https://adspayall.com/assets/images/audio.png';
						} else if (code.substring(0, 1) == 'G') {
							typesrc = 'https://adspayall.com/assets/images/gif.png';
						} else {
							typesrc = 'https://adspayall.com/assets/images/image.png';
						}
						this.campaignbnr.push({
							camp_id: myjson.data['campaigns'][m].camp_id,
							apacode: myjson.data['campaigns'][m].apacode,
							camp_name: myjson.data['campaigns'][m].camp_name,
							cta: myjson.data['campaigns'][m].cta,
							enddate: myjson.data['campaigns'][m].enddate,
							endtime: myjson.data['campaigns'][m].endtime,
							filepath: myjson.data['campaigns'][m].filepath,
							code: code,
							btnimg: "https://secure.adspayall.com/assets/campaign_button/" + code + ".png",
							type: type,
							typesrc: typesrc,
							ctaurl: myjson.data['campaigns'][m].weblink
						});
					}
					this.apa = !this.apa;
				} else if (status === 201) {
					this.apa = !this.apa;
					this.loading = true;
				} else {
					this.generalService.logout();
				}
			});

	}

	next() {
		if (this.currrentIndex < (this.campaignbnr.length - 1)) {
			this.campend = false;
			this.currrentIndex = this.currrentIndex + 1;
		} else {
			this.campend = true;
			this.currrentIndex = this.campaignbnr.length;
		}
	}

	previous() {
		if (this.currrentIndex > 0) {
			this.campend = false;
			this.currrentIndex = this.currrentIndex - 1;
		} else {
			this.campend = true;
			this.currrentIndex = -1;
		}
	}

	bottom() {
		this.campend = false;
		this.currrentIndex = this.campaignbnr.length - 1;
	}

	top() {
		this.campend = false;
		this.currrentIndex = 0;
	}

	openModalCenter(val) {
		document.getElementById(val).classList.remove('fade');
	}

	onCategoryChange(e) {
		if (e.target.value == 0) {
			this.selcat = [];
			if (e.target.checked) {
				for (var i = 0; i < this.categories.length; i++) {
					this.categories[i].selectd = true;
					this.selcat.push(this.categories[i].cat_id);
				}
				this.zerocatchecked = true;
			} else {
				for (var i = 0; i < this.categories.length; i++) {
					this.categories[i].selectd = false;
				}
				this.selcat = [];
				this.zerocatchecked = false;
			}
		} else {
			if (e.target.checked) {
				this.selcat.push(e.target.value)
			} else {
				this.selcat.forEach((element, index) => {
					if (element == e.target.value)
						this.selcat.splice(index, 1);
				});
				this.zerocatchecked = false;
			}
		}
	}

	ageChange(e) {
		if (e.target.checked) {
			this.finperm = this.finperm | e.target.value;// on check
		} else {
			this.finperm = (e.target.value ^ 2147483647) & this.finperm; //on uncheck
		}
		this.permission = this.finperm;
	}

	getCoordintes() {
		let _this = this;
		var options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0
		};

		function success(pos) {
			var crd = pos.coords;
			var lat = crd.latitude.toString();
			var lng = crd.longitude.toString();
			var coordinates = [lat, lng];

			if (_this.getCookie('AAll_Lat_Cookie') == null) {
				_this.setCookie('AAll_Lat_Cookie', lat, 365);
				_this.setCookie('AAll_Lng_Cookie', lng, 365);
				if (_this.getCookie('AAll_City_Cookie'))
					console.log(`New - Latitude: ${lat}, Longitude: ${lng}`);
				_this.getCity(coordinates);
			} else {
				if (_this.getCookie('AAll_Lat_Cookie') == lat && _this.getCookie('AAll_Lng_Cookie') == lng) {
					if ((_this.getCookie('AAll_City_Cookie') == null) || (_this.getCookie('AAll_State_Cookie') == null) || (_this.getCookie('AAll_Country_Cookie') == null)) {
						console.log('Old Data Corrupted');
						_this.getCity(coordinates);
					} else {
						console.log('Data Already Exists');
						_this.callGetCity();
					}
				} else {
					console.log('Updated Coordinates');
					_this.getCity(coordinates);
				}
			}
			return;
		}

		function error(err) {
			console.warn(`ERROR(${err.code}): ${err.message}`);
		}

		navigator.geolocation.getCurrentPosition(success, error, options);
	}

	callGetCity() {
		this.city = this.getCookie('AAll_City_Cookie');
		this.state = this.getCookie('AAll_State_Cookie');
		this.country = this.getCookie('AAll_Country_Cookie');
		if (this.locationflag == 0 && this.preloadflag == 1) {
			this.applyFilter();
		} else {
			this.locationflag = 1;
		}
	}

	getCity(coordinates) {
		var xhr = new XMLHttpRequest();
		var lat = coordinates[0];
		var lng = coordinates[1];
		let _this = this;

		/*_this.city = 'Kottayam';
		_this.state = 'Kerala';
		_this.country = 'India';
		console.log('Location');
		if (this.locationflag == 0 && this.preloadflag == 1) {
			this.applyFilter();
		} else {
			this.locationflag = 1;
		}
		return;
		*/
		// Paste your LocationIQ token below.
		xhr.open('GET', "https://us1.locationiq.com/v1/reverse.php?key=pk.86502eb3f38091824deb0aa368fb9059&lat=" + lat + "&lon=" + lng + "&format=json", true);
		xhr.send();
		//xhr.onreadystatechange = processRequest;
		xhr.addEventListener("readystatechange", processRequest, false);

		function processRequest(e) {
			if (xhr.readyState == 4 && xhr.status == 200) {
				var response = JSON.parse(xhr.responseText);
				var county = response.address.county;
				_this.city = response.address.county;
				_this.state = response.address.state;
				_this.country = response.address.country;
				_this.setCookie('AAll_City_Cookie', _this.city, 365);
				_this.setCookie('AAll_State_Cookie', _this.state, 365);
				_this.setCookie('AAll_Country_Cookie', _this.country, 365);
				if (_this.locationflag == 0 && _this.preloadflag == 1) {
					_this.applyFilter();
				} else {
					_this.locationflag == 1;
				}
				return;
			}
		}
	}

	calc(perm, value) {
		return ((perm & value) == 0) ? false : true;
	}

	setCookie(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}

	getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	apaclick(code, camp_id) {
		document.getElementById("apa-writing-" + code).style.display = "none";
		document.getElementById("imgover-" + code).style.display = "none";
		document.getElementById("apalogo-" + code).style.visibility = "hidden";
		document.getElementById("apatype-" + code).style.visibility = "hidden";
		let time = code.substring(code.length - 3);
		time = time * 1000;
		setTimeout(function () {
			document.getElementById("apabtn-" + code).style.display = "inline-block";
		}, time);
		this.viewedcamps.push(camp_id)
		this.viewedcamps = this.viewedcamps.filter(function (elem, index, self) {
			return index === self.indexOf(elem);
		});
		this.setCookie('AAll_Cookie', JSON.stringify(this.viewedcamps), 365);

		let cookie = this.getCookie('AAll_Cookie');
		console.log('cookie:::' + cookie);

		this._http.post(User.API_ENDPOINT + 'updateImpression', JSON.stringify({ campid: camp_id }))
			.subscribe((response: Response) => {
				let res = { 'results': JSON.stringify(response) };
				let myjson = JSON.parse(res.results);
				const status = myjson.status;
				//console.log(response)
				if (status === 200) {

				}
			});
	}

	apabtncode(code) {
		window.open("https://adspayall.com/mobilepublications/" + code, '_blank');
	}

	ctabtn(camp_id, url) {
		this._http.post(User.API_ENDPOINT + 'updateClick', JSON.stringify({ campid: camp_id }))
			.subscribe((response: Response) => {
				let res = { 'results': JSON.stringify(response) };
				let myjson = JSON.parse(res.results);
				const status = myjson.status;
				//console.log(response)
				if (status === 200) {

				}
			});
		window.open(url, '_blank');
	}

}
