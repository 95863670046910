import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { GeneralService } from '../panel-shared/shared/general.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { User } from '../panel-shared/user';
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css', '../../assets/css/material-dashboard.css']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	passForm: FormGroup;
	error = '';
	loading = false;
	btnText = 'Lets Go';
	erroremail = false;
	notexist = false;
	mailerror = false;
	mailsend = false;
	load = false;
	show: boolean = false;
	role = localStorage.getItem('role');
	constructor(
		private router: Router,
		private _http: HttpClient,
		public generalService: GeneralService,
	) { }

	ngOnInit() {
		this.loginForm = new FormGroup({
			'uname': new FormControl(null, [Validators.required]),
			'password': new FormControl(null, [Validators.required])
		})
		this.passForm = new FormGroup({
			'email': new FormControl(null, [Validators.required]),
		})
	}

	login() {
		this.loading = true;
		this.error = '';
		this.btnText = 'Please Wait...';
		this.markFormTouched(this.loginForm);
		if (this.loginForm.valid) {
			this.generalService.login(JSON.stringify(this.loginForm.value))
				.subscribe(result => {
					if (result[0] === true) {
						this.error = '';
						if (result[1] === 0) {
							this.router.navigate(['admin']);
						} else if (result[1] === 2) {
							this.router.navigate(['advertiser-dashboard']);
						} else {
							this.loading = false;
							this.btnText = 'Lets Go';
							this.error = '* Something Went Wrong, Invalid Role!';
						}
					} else {
						this.btnText = 'Lets Go';
						this.loading = false;
						if (result[1] === 400) {
							this.error = '* The Password Provided Is Invalid';
						} else if (result[1] === 401) {
							this.error = '* Your Account Is Pending Activation';
						} else {
							this.error = '* Please Provide a Valid Username';
						}
					}
				});
		}
	}

	markFormTouched(forms) {
		for (let i in forms.controls)
			forms.controls[i].markAsTouched();
	}

	sendMail() {
		let email = this.passForm.controls['email'].value;
		if (email == null) {
			this.erroremail = true;
		} else if (this.notexist == false) {
			this.mailsend = false;
			this.erroremail = false;
			this.load = true;
			this._http.post(User.API_ENDPOINT + 'Admin/psdRecover', JSON.stringify({ email: email }))
				.subscribe((response: Response) => {
					this.load = false;
					let res = { 'results': JSON.stringify(response) };
					let myjson = JSON.parse(res.results);
					let status = myjson.status;
					if (status == 200) {
						this.mailsend = true;
					} else if (status == 400) {
						this.mailerror = true;
					} else if (status == 401) {
						this.notexist = true;
					}
				});
		}
	}

	existEmail(mail) {
		this.erroremail = false;
		this._http.post(User.API_ENDPOINT + 'Admin/existEmail', JSON.stringify({ email: mail }))
			.subscribe((response: Response) => {
				//console.log(response);
				this.load = false;
				let res = { 'results': JSON.stringify(response) };
				let myjson = JSON.parse(res.results);
				let status = myjson.status;
				if (status == 200) {
					this.notexist = false;
				} else {
					this.notexist = true;
				}
			});
	}

}
