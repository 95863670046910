import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { from } from 'rxjs';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordCreateComponent } from './password-create/password-create.component';
import { LogoutComponent } from './logout/logout.component';
import { HomeComponent } from './front-end/home/home.component';
import { CampaignsComponent } from './front-end//campaigns/campaigns.component';
import { PublisherComponent } from './front-end/publisher/publisher.component';

import { AuthService } from './panel-shared/shared/auth.service';
import { AuthInterceptor } from './services/AuthInterceptor';
import { RoleGuard } from './role.guard';

const appRoutes: Routes = [
	{ path: 'advertiser-dashboard', data: { preload: true, role: 2 }, loadChildren: () => import(`./advertiser/advertiser.module`).then(m => m.AdvertiserModule), canActivate: [RoleGuard] },
	{ path: 'admin', data: { preload: true, role: 0 }, loadChildren: () => import(`./admin/admin.module`).then(m => m.AdminModule), canActivate: [RoleGuard] },

	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'create-password', component: PasswordCreateComponent },
	{ path: 'logout', component: LogoutComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'publisher', component: PublisherComponent },
	{ path: 'campaign', component: CampaignsComponent },
	{ path: '', component: HomeComponent },
	{ path: '**', component: NotFoundComponent },

];

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		PublisherComponent,
		CampaignsComponent,
		LoginComponent,
		NotFoundComponent,
		ResetPasswordComponent,
		PasswordCreateComponent,
		LogoutComponent,
	],
	imports: [
		RouterModule.forRoot(appRoutes),
		BrowserModule,
		ReactiveFormsModule,
		FormsModule,
		HttpModule,
		HttpClientModule,
		ToastrModule.forRoot({
			timeOut: 5000,
			newestOnTop: true,
			positionClass: 'toast-bottom-left',
			preventDuplicates: true,
		}),
		BrowserAnimationsModule,
		GooglePlaceModule,
		SelectDropDownModule
	],
	providers: [
		RoleGuard,
		AuthService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
