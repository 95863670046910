import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/panel-shared/user';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
	selector: 'app-password-create',
	templateUrl: './password-create.component.html',
	styleUrls: ['./password-create.component.css', '../../assets/css/material-dashboard.css']
})
export class PasswordCreateComponent implements OnInit {

	createForm: FormGroup;
	loading = false;
	btnText = 'Submit';
	prop: any;
	mismaterr: boolean = false;
	constructor(private formBuilder: FormBuilder,
		private _http: HttpClient,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		private router: Router,
	) { }

	ngOnInit() {
		this.createForm = new FormGroup({
			'password': new FormControl(null, Validators.required),
			'confpass': new FormControl(null, Validators.required),
			'token': new FormControl(null)
		})
		this.route.queryParams.subscribe((params) => {
			if (params['value']) {
				this.prop = params['value'];
			}
		});
	}

	markFormTouched(forms) {
		for (let i in forms.controls)
			forms.controls[i].markAsTouched();
	}

	createPassword() {
		this.markFormTouched(this.createForm);
		let pass = this.createForm.controls['password'].value;
		let cpass = this.createForm.controls['confpass'].value;
		if (cpass != null) {
			if (pass == cpass) {
				this.mismaterr = false;
			} else {
				this.mismaterr = true;
			}
		}

		if (this.createForm.valid && this.mismaterr == false) {
			this.createForm.controls['token'].setValue(this.prop);
			this.btnText = 'Please wait...';
			this.loading = true;
			this._http
				.post(User.API_ENDPOINT + 'createPassword', JSON.stringify(this.createForm.value))
				.subscribe((response: Response) => {
					let res = { 'results': JSON.stringify(response) };
					let myjson = JSON.parse(res.results);
					const status = myjson.status;
					if (status === 200) {
						this.btnText = 'Submit';
						this.loading = false;
						this.toastr.success('Password created successfully', '');
						this.createForm.reset();
						this.router.navigate(['/']);
					}
				});

		}
	}
}
